import styled from '@emotion/styled'
import { Fragment } from 'react'
import AuthButton from '../components/auth-button'
import AuthInput, {
  AuthInputError,
  AuthInputGroup
} from '../components/auth-input'
import AuthLink from '../components/auth-link'
import AuthTitle from '../components/auth-title'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { signupInvitedMember } from '../api/auth'
import axios from 'axios'
import { ErrorType } from '../api'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useToasts } from '../components/ToastsComponent/toast-store'
import { ValueOf } from '@elastic/eui'

const ForgotForm = styled.form`
  padding-bottom: 20px;
`

export const InviteSchema = yup.object({
  firstname: yup.string().required().min(2).meta({
    placeholder: 'First name',
    type: 'text'
  }),
  lastname: yup.string().required().min(2).meta({
    placeholder: 'Last name',
    type: 'text'
  }),
  password: yup.string().required().min(6).meta({
    placeholder: 'Password',
    type: 'password'
  })
})
type InviteSchemaType = yup.InferType<typeof InviteSchema>

function InvitePage() {
  const navigate = useNavigate()
  const { addToast } = useToasts()
  const {
    formState: { isSubmitting, errors },
    register,
    handleSubmit,
    setError
  } = useForm<InviteSchemaType>({
    resolver: yupResolver(InviteSchema)
  })
  const [query] = useSearchParams()
  const handleOnSubmit = handleSubmit(async (data) => {
    try {
      await signupInvitedMember({
        body: {
          ...data,
          email: query.get('email') as string,
          inviteToken: query.get('token') as string
        }
      })
      addToast({
        title: 'Success',
        color: 'success',
        text: <p>Account created successfully!</p>,
        id: `${Math.random()}`
      })
      navigate('/auth/login')
    } catch (err) {
      if (axios.isAxiosError(err)) {
        setError('password', {
          message:
            (err?.response?.data as ErrorType)?.message ||
            'Something went wrong.'
        })
      }
    }
  })
  return (
    <Fragment>
      <ForgotForm onSubmit={handleOnSubmit} autoComplete="off">
        <AuthTitle>Sign up using invite</AuthTitle>
        <input autoComplete="false" name="hidden" type="text" hidden />
        {Object.entries(InviteSchema.fields).map((field) => {
          const name = field[0] as keyof InviteSchemaType
          const property = field[1] as ValueOf<typeof InviteSchema.fields>
          return (
            <AuthInputGroup key={name}>
              <AuthInput
                {...register(name)}
                placeholder={property.spec.meta.placeholder}
                type={property.spec.meta.type}
                autoComplete={name}
              />
              {errors[name] && (
                <AuthInputError>{errors[name]?.message}</AuthInputError>
              )}
            </AuthInputGroup>
          )
        })}
        <AuthButton type="submit" width="100%" disabled={isSubmitting}>
          Sign up
        </AuthButton>
      </ForgotForm>
      <AuthLink to="/auth/login">Back to sign in</AuthLink>
    </Fragment>
  )
}

export default InvitePage
