import axios from 'axios'
import * as yup from 'yup'
import { WithSignal } from './index'

export const LoginSchema = yup.object({
  username: yup.string().email().required().min(5),
  password: yup.string().required().min(6)
})

export type LoginFields = yup.InferType<typeof LoginSchema>

type LoginResponse = {
  expires: number
  accessToken: string
  tokenType: string
}

export type TokenUser = {
  iat: number
  exp: number
  sub: string
  firstname: string
  lastname: string
  tenant_id: string
  roles: string[]
  default_tenant_user: boolean
}

type RestorePasswordBody = {
  confirmationCode: number
  email: string
  newPassword: string
}

type StatusResponse = {
  status: string
  message: string
}

export const login = async ({
  body,
  signal
}: WithSignal<{ body: LoginFields }>) => {
  const { data } = await axios.post<LoginResponse>('api/login/v1', body, {
    signal
  })
  return data
}

export const resetPassword = async ({
  email,
  signal
}: WithSignal<{ email: string }>) => {
  const { data } = await axios.post<StatusResponse>(
    `management/api/password-reset/v1/${email}`,
    null,
    {
      signal
    }
  )
  return data
}

export const restorePassword = async ({
  body,
  signal
}: WithSignal<{ body: RestorePasswordBody }>) => {
  const { data } = await axios.post<StatusResponse>(
    `management/api/password-reset/v1`,
    body,
    {
      signal
    }
  )
  return data
}

interface SignupInvitedMemberBody {
  firstname: string
  lastname: string
  email: string
  password: string
  inviteToken: string
}
export const signupInvitedMember = async ({
  body,
  signal
}: WithSignal<{ body: SignupInvitedMemberBody }>) => {
  const { data } = await axios.post<StatusResponse>(
    `/management/api/invite/v1/join`,
    body,
    {
      signal
    }
  )
  return data
}
