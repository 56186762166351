import { Fragment, MouseEvent, useRef, useState } from 'react'
// @ts-ignore
import { EuiButton, EuiConfirmModal } from '@aiva3/eui'
import styled from '@emotion/styled'
import { createPortal } from 'react-dom'
import KEYS from '../constants/keys'
import { useToasts } from './ToastsComponent/toast-store'

type ConfirmationModalButtonProps = {
  title?: string
  cancelButtonText?: string
  confirmButtonText?: string
  innerText?: any
  asyncFunc: () => void
  onSuccess?: () => void
  doNotConfirm?: boolean
  children: any

  color: string
  size: 's'
}

function ConfirmationModalButton({
  title = 'Are you sure want to do this?',
  cancelButtonText = 'No',
  confirmButtonText = 'Yes',
  innerText = null,
  asyncFunc,
  onSuccess,
  children,
  doNotConfirm = false,
  ...buttonProps
}: ConfirmationModalButtonProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const closeModal = () => setIsOpen(false)
  const openModal = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    doNotConfirm ? onConfirm(e) : setIsOpen(true)
  }
  const { addToast } = useToasts()
  const subRoot = useRef(document.getElementById(KEYS.SUB_ROOT))

  const onConfirm = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    try {
      setIsLoading(true)
      await asyncFunc()
      setIsLoading(false)
      if (onSuccess) onSuccess()
    } catch (err) {
      if (err instanceof Error) {
        addToast({
          title: 'Error',
          color: 'danger',
          text:
            err.message || 'There was an error while connecting to service.',
          id: Math.random().toString()
        })
      }
    } finally {
      closeModal()
    }
  }

  return (
    <Fragment>
      <EuiButton
        size="s"
        className="logoutButton"
        color="warning"
        onClick={openModal}
      >
        {children}
      </EuiButton>
      {isOpen
        ? createPortal(
            <OverlayMask>
              <EuiConfirmModal
                title={title}
                onCancel={closeModal}
                onConfirm={onConfirm}
                cancelButtonText={cancelButtonText}
                confirmButtonText={confirmButtonText}
                defaultFocusedButton="confirm"
                buttonColor={buttonProps.color}
                confirmButtonDisabled={isLoading}
              >
                {innerText}
              </EuiConfirmModal>
            </OverlayMask>,
            subRoot.current as Element
          )
        : null}
    </Fragment>
  )
}

const OverlayMask = styled.div`
  position: fixed;
  z-index: 6000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10vh;
  animation: euiAnimFadeIn 150ms ease-in;
  background: rgba(255, 255, 255, 0.8);
`

export default ConfirmationModalButton
