import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

const AuthLink = styled(Link)`
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0px;
`

export default AuthLink
