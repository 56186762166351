import { EuiProvider } from '@elastic/eui'
import { css, Global } from '@emotion/react'
import axios from 'axios'
import { createRoot } from 'react-dom/client'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import 'src/styles/eui_theme_light.css'
import Toasts from './components/ToastsComponent/toasts'
import { CC_PUBLIC_URL, GATEWAY_URL } from './config'
import queryClient from './query-client'
import useAppRoutes from './routes'

axios.defaults.baseURL = GATEWAY_URL

function App() {
  return useAppRoutes()
}

const globalStyles = css`
  html {
    background-color: #fff !important;
    font-family: Source Sans Pro, sans-serif !important;
  }
  .euiBody-hasPortalContent {
    position: static;
  }
  .euiTab {
    font-family: Source Sans Pro, sans-serif !important;
    padding: 8px !important;
    font-size: 14px !important;
  }
  .helper-text {
    color: #69707d !important;
    font-size: 12px !important;
    line-height: 1.5 !important;
  }
  .euiFlyout.flyout-members {
    padding: 0 !important;
    width: 480px !important;

    .euiFlyoutHeader {
      padding: 20px 40px 14px 54px;
    }

    .euiFlyout__closeButton {
      right: 45px;
      top: 17px !important;
    }

    .euiFlyoutBody .euiFlyoutBody__overflow {
      padding: 0;
      padding-top: 40px;
      width: 370px;
      margin: auto;
      overflow: hidden;
    }

    .euiFlexGroup--directionRow {
      flex: 0;
      padding-bottom: 30px;
    }
    .euiForm {
      height: 100%;
      display: flex;
      flex-direction: column;
      //padding-bottom: 50px;

      &:last-child span.euiFlexGroup {
        margin-top: auto;
        align-self: flex-end;
      }

      button[type='button'].euiButton:not(.generatePasswordButton) {
        width: 148px !important;
        height: 40px !important;
        border-radius: 21px !important;
      }
    }
  }
  .euiModalBody:last-of-type .euiModalBody__overflow {
    padding-bottom: 0 !important;
  }

  .ChatContainer {
    .header-menu {
      color: #000000 !important;
    }
  }
`

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <EuiProvider colorMode="light">
    <Global styles={globalStyles} />
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename={CC_PUBLIC_URL}>
        <Global styles={globalStyles} />
        <App />
        <Toasts />
      </BrowserRouter>
    </QueryClientProvider>
  </EuiProvider>
)
