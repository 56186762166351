import { ENV } from './globals'

const DEV_SERVER = 'exp'
const DEV_HOST = `${DEV_SERVER}.aiva.inoviaai.se`

window.ENV ||= {} as ENV

export const {
  CC_PUBLIC_URL = '',
  GATEWAY_URL = `https://${DEV_HOST}`,
  WEBSOCKET_DEV_URL = `wss://${DEV_HOST}/ws`
} = window.ENV
