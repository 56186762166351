import React from 'react'
import styled from '@emotion/styled'
import backgroundImg from '../assets/img/background.png'
import { Outlet } from 'react-router-dom'
import slideIn from '../styled/keyframes/slide-in'
import LogoSvg from '../assets/svg/logo.svg'
import bounceIn from '../styled/keyframes/bounce-in'

const AuthPage = styled.div`
  width: 100vw;
  height: 100vh;
  background: url(${backgroundImg}) center center/cover no-repeat;

  .euiText {
    color: #fff;
  }
`

const AuthWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(0, -50%);
  background: transparent;
  width: 340px;
  padding: 0;
  position: absolute;
  margin: 0px;
  will-change: transform;
`

const AnimationLayer = styled.div`
  animation: ${slideIn} 1s ease-out;
`

const LogoImg = styled.img`
  position: fixed;
  top: 35px;
  left: 15px;
  height: 60px;
  animation: ${bounceIn} 0.5s ease-in-out;
`

function AuthLayout() {
  return (
    <AuthPage>
      <LogoImg src={LogoSvg} />
      <AuthWrapper>
        <AnimationLayer>
          <Outlet />
        </AnimationLayer>
      </AuthWrapper>
    </AuthPage>
  )
}

export default AuthLayout
