const KEYS = {
  // query keys
  LOAD_STYLES: 'query.load.styles',
  // other
  PLUGIN_NAME_END: 'plugin.name.end',
  SUB_ROOT: 'sub.root',
  DISPLAY_LANGUAGE: 'display-language'
} as const

export default KEYS

export const USER_ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
  AGENT: 'AGENT'
}
export const USER_ROLES_LIST = Object.keys(USER_ROLES)
