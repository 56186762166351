import axios from 'axios'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useUser } from './use-auth'
import { useTenantId } from './use-tenant-id'

export const TENANTS_KEY = 'TENANTS_KEY'

type Tenant = {
  id: string
  name: string
  default: boolean
}

export function useTenants() {
  const { tenantId, setTenantId } = useTenantId()
  const { isSuperAdmin } = useUser()
  const [defaultTenant, setDefaultTenant] = useState<Tenant | undefined>()

  const retVal = {
    ...useQuery(
      TENANTS_KEY,
      ({ signal }) =>
        axios
          .get<Tenant[]>('management/api/tenants/v1', { signal })
          .then((res) => res.data),
      {
        onSuccess(data) {
          const defaultListTenant = data.find((tenant) => tenant.default)
          setDefaultTenant(defaultListTenant)
          if (!tenantId) {
            setTenantId(defaultListTenant?.id || '')
          }
        },
        enabled: isSuperAdmin
      }
    ),
    defaultTenant
  }

  return retVal
}
