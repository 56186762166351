import styled from '@emotion/styled'

export const LinkButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;

  &:hover {
    text-decoration: underline;
  }
`
