import { keyframes } from '@emotion/react'

const bounceIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  75% {
    transform: scale(1.05);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

export default bounceIn
