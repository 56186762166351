import React, { useRef, useState } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import IframeResizer from 'iframe-resizer-react'
import PATHS from '../constants/paths'
import { Helmet } from 'react-helmet'
import InoviaLogo from '../assets/img/Inovia_Logo_Blue_Green.svg'
import styled from '@emotion/styled'

const StyledHeader = styled.header`
  height: 50px;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  align-items: center;
  background-color: #06112c;
  font-family: Nunito, sans-serif;
  font-size: 18pt;
  color: #fff;
  width: 100%;
`

function DemoPage() {
  const [searchParams] = useSearchParams()
  const iframeRef = useRef(null)
  const [env] = useState(() => {
    const prodEnv = window.location.host.split('.')[0]
    if (prodEnv.startsWith('localhost')) return 'dev'
    return prodEnv
  })

  const [iframeUrl, apiKey] = [
    searchParams.get('iframeUrl'),
    searchParams.get('apiKey')
  ]

  if (iframeUrl && apiKey)
    return (
      <>
        <Helmet>
          <title>Demo UI</title>
          <script
            async
            defer
            id="inovia-script"
            src={`https://storage.googleapis.com/aiva-${env}-script/chat.min.js`}
            data-public-key={apiKey}
          />

          <script>{`const urlParams = new URLSearchParams(window.location.search)
      const emphasizeMessage = urlParams.get('emphasizeMessage')

      window.inovia =
        window.inovia ||
        function () {
          ;(inovia.q = inovia.q || []).push(arguments)
        }
      window.inovia('set', {
        primaryColor: '#011f78',
        baseHost: '${env}.aiva.inoviaai.se',
        useGateway: true,
        emphasizeMessages: emphasizeMessage
          ? [{ en_US: emphasizeMessage, sv_SE: emphasizeMessage }]
          : [
              {
                en_US: 'Hi, do you need help? Just ask me!'
              },
              {
                en_US: 'Hi, do you need help?'
              }
            ],
        defaultLocale: 'en_US',
        languages: emphasizeMessage
          ? [
              {
                locale: 'en_US',
                label: 'English'
              },
              {
                locale: 'sv_SE',
                label: 'Swedish'
              }
            ]
          : undefined,
        emphasizeDelay: 1000,
        emphasizeDisappear: 3000,
        initialGreetingMessage: 'hej',
        isRecorderEnabled: true,
        onEvent: (type, data, messages) => {
          switch (type) {
            case 'loaded':
              break
            case 'new_session':
              window.inovia('update', {
                properties: {
                  name: 'John Doe'
                }
              })
          }
        }
      })`}</script>
        </Helmet>
        <StyledHeader>
          <img
            src={InoviaLogo}
            alt="Inovia_Logo_Blue_Green"
            width="130"
            height="55"
          />
          <p>Demosite</p>
        </StyledHeader>
        <IframeResizer
          forwardRef={iframeRef}
          src={iframeUrl}
          inPageLinks
          checkOrigin={false}
          style={{
            width: '1px',
            minWidth: '100%',
            minHeight: '100%',
            pointerEvents: 'none',
            height: '6000px'
          }}
          log={false}
          heightCalculationMethod="max"
        />
      </>
    )

  return <Navigate to={PATHS.PLUGIN} />
}

export default DemoPage
