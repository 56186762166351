import styled from '@emotion/styled'

const AuthTitle = styled.h1`
  font-family: 'Nunito', sans-serif;
  color: #fafafa;
  font-size: 55px;
  letter-spacing: 0px;
  margin-bottom: 48px;
  width: max-content;
`

export default AuthTitle
