import { EuiIcon } from '@aiva3/eui'
import { EuiButton } from '@elastic/eui'
import styled from '@emotion/styled'
import {
  createContext,
  FC,
  memo,
  MutableRefObject,
  Suspense,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import colors from '../colors'
import Sidebar from '../components/dashboard/sidebar'
import Progress from '../components/progress'
import { TenantSwitcher } from '../components/tenant-switcher'
import KEYS from '../constants/keys'
import PATHS from '../constants/paths'
import { useUser } from '../hooks/use-auth'
import { useTenantId } from '../hooks/use-tenant-id'
import { pluginComponents, PluginNames } from '../plugins'

export const SIDEBAR_WIDTH = 180

const DashboardDiv = styled.div`
  width: 100vw;
`

const MainDiv = styled.div<{ mainTop: string }>`
  left: ${SIDEBAR_WIDTH}px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: ${({ mainTop }) => mainTop};
  bottom: 0;
  overflow-y: scroll;
`

const InnerMainDiv = styled.div`
  padding: 18px;
  position: relative;
`

const PluginNameH1 = styled.h1`
  color: ${colors.navy};
  font-family: 'Nunito', sans-serif;
  font-weight: 800;
  font-size: 36px;
  user-select: none;
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  margin-top: 15px;
`

const RenderPlugin = memo(() => (
  <Suspense fallback={<Progress absoluteCenter />}>
    <Outlet />
  </Suspense>
))

const ErrorContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-size: 22px;
  }

  div.buttons {
    margin-top: 20px;
    display: flex;
    gap: 10px;
  }

  code {
    font-family: 'Courier New', Courier, monospace;
    display: block;
    margin: 20px;
    max-width: 700px;
    color: #e84b4b;
    font-weight: bold;
  }
`
const ErrorFallback: FC<FallbackProps> = memo(
  ({ error, resetErrorBoundary }) => {
    const [show, setShow] = useState(false)
    return (
      <ErrorContainer>
        <EuiIcon type="alert" size="xxl" />
        <h1>There was an error</h1>
        <div className="buttons">
          <EuiButton
            onClick={() => setShow((p) => !p)}
            size="s"
            color="warning"
          >
            Trace
          </EuiButton>
          <EuiButton onClick={resetErrorBoundary} size="s">
            Try again
          </EuiButton>
        </div>
        {show ? <code>{error?.stack}</code> : null}
      </ErrorContainer>
    )
  }
)
type DashboardContextValues = {
  cogElementRef?: MutableRefObject<HTMLDivElement | null>
}
const DashboardContext = createContext({} as DashboardContextValues)
export const useDashboardContext = () => useContext(DashboardContext)

function DashboardLayout() {
  const cogElementRef = useRef<HTMLDivElement | null>(null)
  const { user, isSuperAdmin } = useUser()
  const { tenantId } = useTenantId()
  const { pathname } = useLocation()
  const pluginName = pathname.split('/')[2] as PluginNames
  const pluginDetails = pluginName
    ? pluginComponents[pluginName]
    : pluginComponents['start-page']
  const isPluginHidden = useMemo(
    () =>
      user?.roles.some((role) => pluginDetails.hiddenForRoles.includes(role)),
    [pluginDetails.hiddenForRoles, user?.roles]
  )

  if (!pluginDetails || isPluginHidden) return <Navigate to={PATHS.PLUGIN} />

  return (
    <DashboardContext.Provider value={{ cogElementRef }}>
      <DashboardDiv>
        <Sidebar mainWidth={`${SIDEBAR_WIDTH}px`} />
        <MainDiv key={tenantId} mainTop={isSuperAdmin ? '40px' : '0px'}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {pluginName ? (
              <InnerMainDiv>
                <PluginNameH1>
                  {pluginDetails.name}
                  <div ref={cogElementRef} id={KEYS.PLUGIN_NAME_END} />
                </PluginNameH1>
                <RenderPlugin />
              </InnerMainDiv>
            ) : (
              <RenderPlugin />
            )}
          </ErrorBoundary>
        </MainDiv>
        <TenantSwitcher sidebarWidth={`${SIDEBAR_WIDTH}px`} />
      </DashboardDiv>
    </DashboardContext.Provider>
  )
}

export default DashboardLayout
