import { EuiLoadingSpinner, EuiText } from '@aiva3/eui'
import styled from '@emotion/styled'
import React, { Fragment } from 'react'
import AuthButton from '../components/auth-button'
import AuthInput, {
  AuthInputError,
  AuthInputGroup
} from '../components/auth-input'
import AuthLink from '../components/auth-link'
import AuthTitle from '../components/auth-title'
import { css } from '@emotion/css'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { resetPassword } from '../api/auth'
import axios from 'axios'
import { ErrorType } from '../api'
import { useNavigate } from 'react-router-dom'
import { useToasts } from '../components/ToastsComponent/toast-store'

const ForgotForm = styled.form`
  padding-bottom: 20px;
`

const euiTextClass = css`
  font-size: 14px;
  font-weight: 700;
  font-family: 'Source Sans Pro', sans-serif;
`

export const ForgotSchema = yup.object({
  username: yup.string().email().required().min(5)
})
function ForgotPage() {
  const navigate = useNavigate()
  const { addToast } = useToasts()
  const {
    formState: { isSubmitting, errors },
    register,
    handleSubmit,
    setError
  } = useForm<yup.InferType<typeof ForgotSchema>>({
    resolver: yupResolver(ForgotSchema)
  })

  const handleOnSubmit = handleSubmit(async ({ username }) => {
    try {
      const res = await resetPassword({
        email: username
      })
      addToast({
        title: 'Success',
        color: 'success',
        text: <p>{res?.message}</p>,
        id: `${Math.random()}`
      })
      navigate('/auth/reset')
    } catch (err) {
      if (axios.isAxiosError(err)) {
        setError('username', {
          message:
            (err?.response?.data as ErrorType)?.message ||
            'Something went wrong.'
        })
      }
    }
  })
  return (
    <Fragment>
      <ForgotForm onSubmit={handleOnSubmit}>
        <AuthTitle>Reset password</AuthTitle>
        <EuiText size="m" className={euiTextClass}>
          Please enter your registered email and we'll send you a link to reset
          your password.
        </EuiText>
        <AuthInputGroup>
          <AuthInput
            {...register('username')}
            placeholder="Enter registered email"
          />
          {errors.username && (
            <AuthInputError>{errors.username.message}</AuthInputError>
          )}
        </AuthInputGroup>
        <AuthButton type="submit" width="100%" disabled={isSubmitting}>
          {isSubmitting ? <EuiLoadingSpinner size="l" /> : 'Reset password'}
        </AuthButton>
      </ForgotForm>
      <AuthLink to="/auth/login">Back to sign in</AuthLink>
    </Fragment>
  )
}

export default ForgotPage
