import { EuiIcon } from '@aiva3/eui'
import { EuiButtonEmpty, EuiFilterSelectItem, EuiPopover } from '@elastic/eui'
import { css } from '@emotion/css'
import styled from '@emotion/styled'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import pkg from '../../package.json'
import colors from '../colors'
import { useUser } from '../hooks/use-auth'
import { useTenantId } from '../hooks/use-tenant-id'
import { useTenants } from '../hooks/use-tenants'

const SuperAdminBar = styled.div<{ sidebarWidth: string }>`
  background-color: ${colors.adminBarBg};
  color: ${colors.navy};
  position: fixed;
  top: 0;
  right: 0;
  user-select: none;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  padding: 0 25px;

  label {
    font-size: 14px !important;
  }

  .euiPanelTenants {
    div {
      padding: 0 !important;
    }
  }
`

const VersionDiv = styled.div`
  margin-left: auto;
  font-size: 14px !important;
`

const SelectDiv = styled.div`
  margin-left: 35px;

  .euiButtonEmpty:not(.euiButtonEmpty--ghost) {
    color: ${colors.navy}!important;
    font-size: 14px !important;
  }

  .euiButtonEmpty .euiButtonEmpty__text {
    font-family: 'Nunito', sans-serif !important;
    font-weight: bold !important;
  }

  .euiButtonEmpty {
    .arrowDown-icon {
      margin-left: 8px;
      margin-bottom: 2px;
    }
  }
`
const itemCss = (isActive: boolean) => css`
  padding: 5px 30px 5px 5px;
  text-decoration: none !important;
  background-color: #fff !important;
  color: ${isActive ? '#bbbbbd' : '#535257'}!important;
  outline: none !important;
  &:hover {
    text-decoration: underline !important;
  }
`

export function TenantSwitcher({ sidebarWidth }: { sidebarWidth: string }) {
  const { isSuperAdmin } = useUser()
  const { tenantId, setTenantId } = useTenantId()
  const { data: tenants = [] } = useTenants()
  const [isPopoverOpen, setPopover] = useState(false)
  const navigate = useNavigate()

  const tenantName = useCallback(() => {
    return tenants.find((item) => item.id === tenantId)?.name
  }, [tenantId, tenants])

  const onButtonClick = () => {
    setPopover(!isPopoverOpen)
  }

  const closePopover = () => {
    setPopover(false)
  }

  const button = (
    <EuiButtonEmpty size="s" onClick={onButtonClick}>
      {tenantName()}
      <EuiIcon type="arrowDown" className="arrowDown-icon" />
    </EuiButtonEmpty>
  )

  return isSuperAdmin ? (
    <SuperAdminBar sidebarWidth={sidebarWidth}>
      <label htmlFor="tenantId">Super admin mode</label>
      <SelectDiv>
        <EuiPopover
          id="filter"
          button={button}
          isOpen={isPopoverOpen}
          closePopover={closePopover}
          panelPaddingSize="none"
          anchorPosition="downCenter"
        >
          {tenants.map((item, index) => (
            <EuiFilterSelectItem
              key={index}
              className={itemCss(item.id === tenantId)}
              checked={item.id === tenantId ? 'on' : undefined}
              onClick={() => {
                setTenantId(item.id)
                closePopover()
                navigate('/')
              }}
              disabled={item.id === tenantId}
            >
              {item.name}
            </EuiFilterSelectItem>
          ))}
        </EuiPopover>
      </SelectDiv>
      <VersionDiv>ver. {pkg.version}</VersionDiv>
    </SuperAdminBar>
  ) : null
}
