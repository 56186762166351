import { EuiGlobalToastList } from '@aiva3/eui'
import { useToasts } from './toast-store'

const Toasts = () => {
  const { toasts, removeToast } = useToasts()

  return (
    <EuiGlobalToastList
      toasts={toasts}
      dismissToast={removeToast}
      toastLifeTimeMs={2000}
    />
  )
}

export default Toasts
