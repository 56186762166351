import { EuiLoadingSpinner } from '@aiva3/eui'
import styled from '@emotion/styled'
import { Fragment } from 'react'
import AuthButton from '../components/auth-button'
import AuthInput, {
  AuthInputError,
  AuthInputGroup
} from '../components/auth-input'
import AuthLink from '../components/auth-link'
import AuthTitle from '../components/auth-title'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { restorePassword } from '../api/auth'
import axios from 'axios'
import { ErrorType } from '../api'
import { useNavigate } from 'react-router-dom'
import { useToasts } from '../components/ToastsComponent/toast-store'

const ResetForm = styled.form`
  padding-bottom: 20px;
`

export const ResetSchema = yup.object({
  confirmationCode: yup.number().required(),
  username: yup.string().email().required().min(5),
  newPassword: yup.string().required()
})

function ResetPage() {
  const navigate = useNavigate()
  const { addToast } = useToasts()
  const {
    formState: { isSubmitting, errors },
    register,
    handleSubmit,
    setError
  } = useForm<yup.InferType<typeof ResetSchema>>({
    resolver: yupResolver(ResetSchema)
  })

  const handleOnSubmit = handleSubmit(
    async ({ confirmationCode, username, newPassword }) => {
      const payload = {
        confirmationCode,
        email: username,
        newPassword
      }
      try {
        const res = await restorePassword({ body: payload })
        addToast({
          title: 'Success',
          color: 'success',
          text: <p>{res?.message}</p>,
          id: `${Math.random()}`
        })
        navigate('/auth/login')
      } catch (err) {
        if (axios.isAxiosError(err)) {
          setError('newPassword', {
            message:
              (err?.response?.data as ErrorType)?.message ||
              'Something went wrong.'
          })
        }
      }
    }
  )
  return (
    <Fragment>
      <ResetForm onSubmit={handleOnSubmit}>
        <AuthTitle>Update password</AuthTitle>
        <AuthInputGroup>
          <AuthInput
            {...register('confirmationCode')}
            placeholder="Enter confirmation code"
          />
          {errors.confirmationCode && (
            <AuthInputError>{errors.confirmationCode.message}</AuthInputError>
          )}
        </AuthInputGroup>
        <AuthInputGroup>
          <AuthInput
            {...register('username')}
            placeholder="Enter registered email"
          />
          {errors.username && (
            <AuthInputError>{errors.username.message}</AuthInputError>
          )}
        </AuthInputGroup>
        <AuthInputGroup>
          <AuthInput
            {...register('newPassword')}
            placeholder="Enter new password"
            type="password"
            autoComplete="new-password"
          />
          {errors.newPassword && (
            <AuthInputError>{errors.newPassword.message}</AuthInputError>
          )}
        </AuthInputGroup>
        <AuthButton type="submit" width="100%" disabled={isSubmitting}>
          {isSubmitting ? <EuiLoadingSpinner size="l" /> : 'Update password'}
        </AuthButton>
      </ResetForm>
      <AuthLink to="/auth/login">Back to sign in</AuthLink>
    </Fragment>
  )
}

export default ResetPage
