import { Fragment, useMemo } from 'react'
import { Navigate, Outlet, useRoutes } from 'react-router-dom'
import PATHS from './constants/paths'
import { useIsAuthenticated } from './hooks/use-auth'
import AuthLayout from './layouts/auth-layout'
import DashboardLayout from './layouts/dashboard-layout'
import ForgotPage from './pages/forgot-page'
import InvitePage from './pages/invite-page'
import LoginPage from './pages/login-page'
import plugins, { startPage } from './plugins'
import ResetPage from './pages/reset-page'
import DemoPage from './pages/demo-page'

const isDev = process.env.NODE_ENV === 'development'

function useAppRoutes() {
  const isAuthenticated = useIsAuthenticated()
  const routes = useMemo(
    () => [
      isAuthenticated
        ? {
            path: PATHS.PLUGIN,
            element: <DashboardLayout />,
            children: [
              {
                index: true,
                element: startPage?.element
              },
              ...plugins.map((route) => ({ ...route, path: route.path + '/*' }))
            ]
          }
        : {
            path: 'auth',
            element: <AuthLayout />,
            children: [
              {
                path: 'login',
                element: <LoginPage />
              },
              {
                path: 'forgot',
                element: <ForgotPage />
              },
              {
                path: 'reset',
                element: <ResetPage />
              },
              { path: 'invite', element: <InvitePage /> },
              {
                index: true,
                element: <Navigate to="/auth/login" />
              }
            ]
          },
      {
        path: isDev ? `app/${PATHS.DEMO_UI}` : PATHS.DEMO_UI,
        element: (
          <Fragment>
            <Outlet />
          </Fragment>
        ),
        children: [
          {
            index: true,
            element: <DemoPage />
          }
        ]
      },
      {
        path: '*',
        element: (
          <Navigate to={isAuthenticated ? PATHS.PLUGIN : '/auth/login'} />
        )
      }
    ],
    [isAuthenticated]
  )
  return useRoutes(routes)
}

export default useAppRoutes
