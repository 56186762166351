import styled from '@emotion/styled'
import React from 'react'
import bounceIn from '../styled/keyframes/bounce-in'
import fadeIn from '../styled/keyframes/fade-in'
import rotation from '../styled/keyframes/rotation'
import LogoSmall from '../assets/svg/logo_small.svg'

const AbsoluteCentered = styled.div`
  position: fixed;
  left: calc(50% + 90px);
  top: 50%;
  transform: translate(-50%, -50%);
`

const ProgressWrapper = styled.div`
  height: 128px;
  width: 128px;
  position: relative;

  & + * {
    margin-top: 24px;
  }
`

const ProgressActiveCircle = styled.div`
  height: 128px;
  width: 128px;
  margin: 0 auto;
  position: relative;
  border: 2px solid transparent;
  border-top: 2px solid #017d73;
  border-radius: 100%;
  display: block;
  opacity: 0;
  animation: ${rotation} 0.75s 0.5s infinite linear,
    ${fadeIn} 1s 0.5s ease-in-out forwards;
`

const ProgressCircle = styled.div`
  position: fixed;
  left: 4px;
  top: 4px;
  width: 120px;
  height: 120px;
  padding: 20px;
  border-radius: 50%;
  animation: ${bounceIn} 0.5s ease-in-out;
  background-image: url(${LogoSmall});
  background-repeat: no-repeat;
  background-size: 65px;
  background-position: center;
`

function Progress({ absoluteCenter = false }: { absoluteCenter?: boolean }) {
  const element = (
    <ProgressWrapper>
      <ProgressActiveCircle />
      <ProgressCircle />
    </ProgressWrapper>
  )
  return absoluteCenter ? (
    <AbsoluteCentered>{element}</AbsoluteCentered>
  ) : (
    element
  )
}

export default Progress
