import { keyframes } from '@emotion/react'

const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  50% {
    opacity: 0;
    transform: translateX(-50%);
  }
  88% {
    transform: translateX(5%); 
  }
  100% {
    opacity: 1;
    transform: translateX(0); 
  } 
`

export default slideIn
