import styled from '@emotion/styled'

export const AuthInputGroup = styled.div`
  width: 100%;
  margin-bottom: 2em !important;
  margin-block-start: 2em;
  margin-block-end: 2.5em;
  margin-top: 16px !important;
`

export const AuthInputError = styled.p`
  margin-top: 8px;
  color: red;
`

const AuthInput = styled.input`
  background: transparent;
  border: none;
  border-bottom: 1px solid white;
  border-radius: 0;
  color: white;
  font-size: 1em;
  width: 100%;
  padding: 5px;

  &::placeholder {
    color: #bbbbbd;
  }

  &:focus {
    outline: none;
    animation: none;
  }

  &:disabled {
    color: #bbbbbd;
  }
`

export default AuthInput
