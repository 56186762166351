import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import AuthInput, {
  AuthInputError,
  AuthInputGroup
} from '../components/auth-input'
import AuthButton from '../components/auth-button'
import AuthLink from '../components/auth-link'
import AuthTitle from '../components/auth-title'
import { useForm } from 'react-hook-form'

import { yupResolver } from '@hookform/resolvers/yup'
import { login, LoginFields, LoginSchema } from '../api/auth'
import { useToken } from '../hooks/use-auth'
import axios from 'axios'
import { ErrorType } from '../api'
const LoginForm = styled.form`
  padding-bottom: 20px;

  &__input {
    background: transparent !important;
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0px;
    border: none !important;
    border-bottom: 1px solid #fff !important;

    &.invalid {
      border-bottom-color: #bd271e !important;
    }

    &::placeholder {
      color: #bbbbbd;
    }
  }
  .euiFormHelpText.euiFormRow__text {
    font-size: 10px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #bbbbbd !important;
    letter-spacing: 0px;
  }

  input[placeholder='Choose password']::placeholder,
  input[placeholder='Confirm password']::placeholder {
    color: #fff !important;
  }
  .text {
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0px;
    margin-bottom: 10px;
  }

  .error {
    color: #fd5206;
  }

  .brand-image-container,
  .login-title,
  .login-subtitle,
  .input-group-addon {
    display: none;
  }

  p.error {
    color: #fd5206;
    padding-bottom: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0px;
  }

  .error-message {
    text-align: left;
    margin-bottom: 10px;
  }
  .success-message {
    color: #00f86a;
    width: max-content;
  }
`

function LoginPage() {
  const {
    formState: { isSubmitting, errors },
    register,
    handleSubmit,
    setError
  } = useForm<LoginFields>({ resolver: yupResolver(LoginSchema) })
  const { setToken } = useToken()

  const handleOnSubmit = handleSubmit(async (body) => {
    try {
      const res = await login({
        body
      })
      setToken({
        token: `${res.tokenType} ${res.accessToken}`,
        save: true
      })
    } catch (err) {
      if (axios.isAxiosError(err)) {
        setError('password', {
          message:
            (err?.response?.data as ErrorType)?.message ||
            'Something went wrong.'
        })
      }
    }
  })

  return (
    <Fragment>
      <LoginForm onSubmit={handleOnSubmit}>
        <AuthTitle>Sign in</AuthTitle>
        <AuthInputGroup>
          <AuthInput
            placeholder="Email"
            {...register('username')}
            autoComplete="username"
          />
          {errors.username && (
            <AuthInputError>{errors.username.message}</AuthInputError>
          )}
        </AuthInputGroup>
        <AuthInputGroup>
          <AuthInput
            placeholder="Password"
            type="password"
            {...register('password')}
            autoComplete="new-password"
          />
          {errors.password && (
            <AuthInputError>{errors.password.message}</AuthInputError>
          )}
        </AuthInputGroup>
        <AuthButton type="submit" width="100%" disabled={isSubmitting}>
          Sign in
        </AuthButton>
      </LoginForm>
      <AuthLink to="/auth/forgot">Forgot password?</AuthLink>
    </Fragment>
  )
}

export default LoginPage
