import { atom, useAtom } from 'jotai'
import { useCallback } from 'react'

import { Toast } from '@aiva3/eui/src/components/toast/global_toast_list'

const toastsAtom = atom<Toast[]>([])

const useToasts = () => {
  const [toasts, setToasts] = useAtom(toastsAtom)

  const addToast = useCallback(
    (newToast: Toast) => setToasts((allToasts) => allToasts.concat(newToast)),
    [setToasts]
  )
  const removeToast = useCallback(
    (removedToast: Toast) =>
      setToasts((allToasts) =>
        allToasts.filter((toast) => toast.id !== removedToast.id)
      ),
    [setToasts]
  )

  const showNetworkError = useCallback(
    () =>
      addToast({
        title: 'Network error',
        color: 'danger',
        text: <p>There is a problem connecting to the server</p>,
        id: `${Math.random()}`
      }),
    [addToast]
  )

  const unknownError = useCallback(
    () =>
      addToast({
        title: 'Error',
        color: 'danger',
        text: <p>Something went wrong, please try to refresh the page</p>,
        id: `${Math.random()}`
      }),
    [addToast]
  )

  const showError = useCallback(
    (message: string) =>
      addToast({
        title: 'Error',
        color: 'danger',
        text: <p>{message}</p>,
        id: `${Math.random()}`
      }),
    [addToast]
  )

  return {
    toasts,
    addToast,
    removeToast,
    showNetworkError,
    unknownError,
    showError
  }
}

export { useToasts }
