import styled from '@emotion/styled'
import * as CSS from 'csstype'
import BaseButton from './base-button'

type AuthButtonProps = {
  width?: CSS.Property.Width
}

const AuthButton = styled(BaseButton)<AuthButtonProps>`
  font-family: 'Source Sans Pro', sans-serif;
  background: #3185fc;
  border: none;
  color: white;
  border-radius: 25px;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0px;

  width: ${({ width = 'auto' }) => width};
`

export default AuthButton
