import axios from 'axios'
import { atom, useAtom } from 'jotai'
import pkg from '../../package.json'

function setDefaultTenantHeader(tenantId: string) {
  axios.defaults.headers['X-Tenant-Id'] = tenantId
}

const TENANT_ID_KEY = `${pkg.name}.tenantId`
const initialTenantId = localStorage.getItem(TENANT_ID_KEY) ?? ''

setDefaultTenantHeader(initialTenantId)

const baseTenantIdAtom = atom(initialTenantId)
const tenantIdAtom = atom<string, string>(
  (get) => get(baseTenantIdAtom),
  (_, set, nextTenantId) => {
    setDefaultTenantHeader(nextTenantId)
    localStorage.setItem(TENANT_ID_KEY, nextTenantId)
    set(baseTenantIdAtom, nextTenantId)
  }
)

export const useTenantId = () => {
  const [tenantId, setTenantId] = useAtom(tenantIdAtom)
  return { tenantId, setTenantId }
}
