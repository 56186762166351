import styled from '@emotion/styled'

const BaseButton = styled.button`
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 5px 15px;
  font-size: 14px;
  border-radius: 4px;
  user-select: none;
`

export default BaseButton
